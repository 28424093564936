import React from 'react';

// External Component
import { Box, Heading } from '@thepuzzlers/pieces';
import { colors } from 'gatsby-plugin-theme-ui/colors';

// Local Components
import { DividerLine } from 'components';

// Main Components
export const DineHeading = ({ text, primary = false }) => {
  return (
    <Box>
      <Heading
        as="h2"
        type="h2-300-100"
        sx={{ color: primary ? 'primary' : 'background' }}>
        {text}
      </Heading>
      <DividerLine
        color={primary ? colors.primary : colors.background}
        sx={{
          width: ['76px', '77px', '108px', '70px', '94px', '92px'],
          mt: ['20px', '24px', '24px', '20px', '20px', '24px']
        }}
      />
    </Box>
  );
};
