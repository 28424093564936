import React from 'react';
import { SEO } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { SectionSpacer } from 'components';

// Sections
import {
  useLandingPageData,
  Header,
  Impression,
  About,
  Menu,
  Offer,
  Collaboration,
  Story,
  Map,
  Contact
} from 'sections/landingPage';

const Home = () => {
  const {
    seo,
    header,
    impression,
    about,
    menu,
    offer,
    collaboration,
    story,
    map
  } = useLandingPageData();

  return (
    <>
      <SEO {...seo} />
      <Header data={header} />
      <SectionSpacer
        spacing={['47.03%', '50.41%', '15.16%', '23.25%', '21.42%', '18.76%']}
      />
      <Impression data={impression} />
      <SectionSpacer
        spacing={['75.25%', '46.53%', '33.94%', '11.62%', '24.03%', '18.76%']}
      />
      <About data={about} />
      <SectionSpacer
        spacing={['40.17%', '36.53%', '23.94%', '13.25%', '14.03%', '21.26%']}
      />
      <Menu data={menu} />
      <SectionSpacer
        spacing={['50.17%', '31.02%', '33.94%', '11.62%', '24.03%', '18.76%']}
      />
      <SectionSpacer
        bg="primary"
        spacing={['15.08%', '21.02%', '12.63%', '1.62%', '6.02%', '2.51%']}
      />
      <Offer data={offer} />
      <SectionSpacer
        bg="primary"
        spacing={['50.17%', '31.02%', '22.63%', '23.25%', '24.03%', '18.76%']}
      />
      <SectionSpacer
        spacing={['50.17%', '31.02%', '22.63%', '11.62%', '16.02%', '12.51%']}
      />
      {/* <Collaboration data={collaboration} />
      <SectionSpacer
        spacing={['65.25%', '36.53%', '23.94%', '1.62%', '14.03%', '8.76%']}
      /> */}
      <Story data={story} />
      <SectionSpacer
        spacing={['65.25%', '36.53%', '46.57%', '13.25%', '30.04%', '21.26%']}
      />
      <Map data={map} />
      <SectionSpacer
        spacing={['40.17%', '36.53%', '23.94%', '1.62%', '14.03%', '8.76%']}
      />
      <Contact />
      <SectionSpacer
        spacing={['25.08%', '31.02%', '22.63%', '11.62%', '16.02%', '18.76%']}
      />
    </>
  );
};

export default Home;
