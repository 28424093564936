import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// External Components
import {
  Section,
  Heading,
  Paragraph,
  Box,
  fullWidthMinusMargins
} from '@thepuzzlers/pieces';
import { colors } from 'gatsby-plugin-theme-ui/colors';

// Local Components
import { DividerLine } from 'components';

// Animations
import { revealHeadlineAndLine, revealImage } from 'sections/animations';

// Main Components
export const Map = ({ data: { headline, paragraph, mapImage } }) => {
  const sectionRef = React.useRef(null);

  React.useEffect(() => {
    const elements = [...sectionRef.current.children];
    const line = elements[2].children[0];

    const killAnimation = revealHeadlineAndLine(elements[0], elements[0], line);

    return killAnimation;
  }, []);

  return (
    <Section
      id="map"
      ref={sectionRef}
      sx={{
        gridTemplateRows: [
          null,
          null,
          null,
          'auto auto 1fr', // it will force third row to take maximum space available
          'auto auto 1fr',
          'auto auto 1fr'
        ],
        // spacing
        pt: ['10%', '10%', '10%', '10%', '10%', '10%']
      }}>
      <Headline headline={headline} />
      <MapBox mapImage={mapImage} />
      <TextBlock paragraph={paragraph} />
    </Section>
  );
};

// Elements
const Headline = ({ headline }) => (
  <Heading
    as="h2"
    type="h2-700-115"
    sx={{
      gridColumn: [
        '2 / span 10',
        '2 / span 8',
        '4 / span 12',
        '12 / span 11',
        '13 / span 9',
        '12 / span 8'
      ],
      gridRow: '1 / span 2',
      zIndex: 3,
      color: 'primary'
    }}>
    {headline}
  </Heading>
);

const MapBox = ({ mapImage }) => {
  const wrapperRef = React.useRef(null);

  React.useEffect(() => {
    const cover = wrapperRef.current.children[0];

    const killAnimation = revealImage(wrapperRef.current, cover);

    return killAnimation;
  }, []);

  return (
    <Box
      ref={wrapperRef}
      className="map__map-box"
      sx={{
        gridColumn: [
          '1/13',
          '1 / span 11',
          '2 / span 18',
          '1 / span 13',
          '2 / span 13',
          '2 / span 12'
        ],
        aspectRatio: [
          '116/100',
          '138/100',
          '138/100',
          '136/100',
          '138/100',
          '138/100'
        ],
        gridRow: '2 / span 2',
        alignSelf: 'start',
        ml: [fullWidthMinusMargins[0], 0, 0, 0, 0, 0],
        position: 'relative'
      }}>
      {/* Map cover box for reveal animation */}
      <Box
        className="map-box__cover"
        sx={{
          bg: 'background',
          height: '100%',
          position: 'absolute',
          inset: 0,
          width: '100%',
          zIndex: 1
        }}
      />
      <GatsbyImage image={getImage(mapImage)} />
    </Box>
  );
};

const TextBlock = ({ paragraph }) => {
  return (
    <Box
      className="map__text-block"
      sx={{
        gridColumn: [
          '1 / span 11',
          '5 / span 7',
          '11 / span 11',
          '15 / span 9',
          '16 / span 8',
          '15 / span 8'
        ],
        mt: ['10.96%', '12.46%', '14.18%', '12.64%', '12.21%', '11.54%']
      }}>
      <DividerLine
        color={colors.primary}
        sx={{
          width: ['26%', '27%', '34%', '28%', '28.5%', '22.5%']
        }}
      />
      <Paragraph
        type="p-500-175"
        sx={{
          mt: ['1.31em', '2.23em', '2.56em', '2.33em', '2.56em', '2.72em'],
          pr: [null, null, null, null, null, '5%']
        }}
        dangerouslySetInnerHTML={{ __html: paragraph }}
      />
    </Box>
  );
};
