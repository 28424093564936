import React from 'react';

// External Components
import { navigate } from 'gatsby';
import {
  Heading,
  Section,
  Box,
  FlexWrapper,
  Button
} from '@thepuzzlers/pieces';
import { colors } from 'gatsby-plugin-theme-ui/colors';

// Local Components
import { Vector, DividerLine, startLottie, stopLottie } from 'components';
const LottieContainer = React.lazy(() => import('components'));

// Menu Overlay
import { useFoodData, MenuOverlay } from 'sections/menuOverlay';

// Assets
import waterdrop from 'assets/menu/waterdrop-elements.svg';
import shakyPickup from 'lotties/shaky-pickup.json';
import wavyList from 'lotties/wavy-list.json';

// Animation
import { revealHeadlineAndLine, buttonHoverVariant } from 'sections/animations';

export const Menu = ({ data: { textblock, menu, pickup } }) => {
  const sectionRef = React.useRef(null);

  // Menu overlay handlers
  const [openOverlay, setOpenOverlay] = React.useState(false);

  const handleOpen = () => {
    setOpenOverlay(true);
  };

  const handleClose = () => {
    setOpenOverlay(false);
  };

  // Buttons click handlers - OnClick we navigate
  // to 'In Haus'/'Auber Haus' selected
  const foodData = useFoodData();

  const handleClickMenuCTA = () => {
    handleOpen();
  };

  const handleClickPickUpCTA = () => {
    handleOpen();
    navigate('#ausser-haus');
  };

  // Animations
  React.useEffect(() => {
    const elements = [...sectionRef.current.children];

    const killRevealElements = revealHeadlineAndLine(
      sectionRef.current,
      elements[0],
      elements[1]
    );

    return killRevealElements;
  }, []);

  return (
    // Markup
    <Section
      id="menu"
      ref={sectionRef}
      sx={{
        // spacing
        pt: ['10%', '10%', '10%', '10%', '10%', '10%']
      }}>
      <Headline data={textblock.headline} />
      <Line />
      <Description data={textblock.description} />
      <MenuCTA data={menu} handleClick={handleClickMenuCTA} />
      <PickUpCTA data={pickup} handleClick={handleClickPickUpCTA} />
      {/* Menu overlay */}
      {openOverlay && (
        <MenuOverlay handleClose={handleClose} foodData={foodData} />
      )}
    </Section>
  );
};

// Elements

// Texblock Components

const Headline = ({ data }) => (
  <Heading
    as="h2"
    type="h2-300-100"
    className="menu__headline"
    sx={{
      color: 'primary',
      gridColumn: [
        '1/span 7',
        '2/span 5',
        '2/span 8',
        '2/span 6',
        '3/span 5',
        '4/span 5'
      ],
      textTransform: 'uppercase'
    }}>
    {data}
  </Heading>
);

const Line = () => (
  <DividerLine
    color={colors.primary}
    sx={{
      gridColumn: [
        '1/span 3',
        '2/span 2',
        '2/span 4',
        '2/span 3',
        '3/span 3',
        '4/span 2'
      ],
      mt: ['25.02%', '35.91%', '23.27%', '29.03%', '23.75%', '30.40%']
    }}
  />
);

const Description = ({ data }) => (
  <Heading
    as="h3"
    type="h3-700-140"
    className="menu__description"
    sx={{
      gridColumn: [
        '1/span 10',
        '2/span 10',
        '2/span 14',
        '2/span 17',
        '3/span 12',
        '4/span 12'
      ],
      mt: ['7.93%', '4.44%', '4.66%', '4.95%', '3.63%', '2.85%']
    }}>
    {data}
  </Heading>
);

// Menu and Pickups boxes

const MenuCTA = ({ data, handleClick }) => (
  <CTAWrapper
    {...data}
    handleClick={handleClick}
    sx={{
      gridColumn: [
        '1/13',
        '3/span 7',
        '2/span 10',
        '3/span 9',
        '5/span 8',
        '6/span 7'
      ],
      mt: ['21.14%', '21.56%', '25.06%', '22.12%', '21.98%', '27.63%']
    }}
  />
);

const PickUpCTA = ({ data, handleClick }) => (
  <CTAWrapper
    isMenu={false}
    {...data}
    handleClick={handleClick}
    sx={{
      gridColumn: [
        '1/13',
        '3/span 7',
        '13/span 10',
        '14/span 9',
        '14/span 8',
        '14/span 7'
      ],
      mt: ['12.83%', '21.22%', '25.06%', '22.12%', '21.98%', '27.63%']
    }}
  />
);

const CTAWrapper = ({
  isMenu = true,
  description,
  button,
  handleClick,
  sx
}) => {
  const lottieRef = React.useRef(null);

  return (
    <Box
      className="menu__cta-wrapper"
      sx={{ width: ['100%', '100%', '100%', '100%', '93%', '86%'], ...sx }}>
      <Heading as="h4" type="h4-500-160">
        {description}
      </Heading>
      <FlexWrapper
        className="cta-wrapper__icon-and-button"
        onClick={handleClick}
        onMouseEnter={() => {
          startLottie(lottieRef);
        }}
        onMouseLeave={() => {
          stopLottie(lottieRef);
        }}
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
          mt: ['6.79%', '13.14%', '9.74%', '13.43%', '9.77%', '8.84%'],
          position: 'relative'
        }}
        // Animation values for button
        inital="inital"
        whileHover="hover">
        <CTALottie isMenu={isMenu} lottieRef={lottieRef} />
        <CTAButton {...button} />
        {isMenu ? <MenuWaterdrop /> : <PickupWaterdrop />}
      </FlexWrapper>
    </Box>
  );
};

const CTALottie = ({ isMenu, lottieRef }) => (
  <Box sx={{ width: ['15.06%', '18.52%', '18.2%', '19%', '19.7%', '22.5%'] }}>
    <React.Suspense fallback={<div />}>
      <LottieContainer
        loop={false}
        animationData={isMenu ? wavyList : shakyPickup}
        startWhenInView={false}
        lottieRef={lottieRef}
        useExternalRefs={true}
        sx={{ m: '-15%' }}
      />
    </React.Suspense>
  </Box>
);

const CTAButton = ({ text }) => (
  <Button
    variant="secondary"
    variants={buttonHoverVariant}
    sx={{ ml: ['7.53%', '7.9%', '7.7%', '9%', '10.5%', '10.3%'] }}>
    {text}
  </Button>
);

// Decorations

const MenuWaterdrop = () => (
  <Vector
    src={waterdrop}
    alt={null}
    sx={{
      position: 'absolute',
      right: ['19%', '15%', '12%', '14%', '16%', '15%'],
      bottom: ['-30%', '-30%', '-30%', '-30%', '-15%', '-5%'],
      width: ['4.71%', '5.06%', '5.23%', '5.93%', '4.93%', '4.82%']
    }}
  />
);

const PickupWaterdrop = () => (
  <Vector
    src={waterdrop}
    alt={null}
    sx={{
      position: 'absolute',
      right: ['8%', 0, 0, 0, '5%', '2%'],
      bottom: ['100%', '100%', '100%', '100%', '90%', '88%'],
      transform: 'rotate(80deg)',
      width: ['3.5%', '3.7%', '3.9%', '4.3%', '3.6%', '3.54%']
    }}
  />
);
