import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

// Local Components
import { MenuBlock } from '.';

// Constants
// this type of dishes have to match with type that provided in the json data
// if the type in the json data is modified, it should also modified
const A_LA_CARTE = 'a la carte';
const MENU = 'menu';

export const MenuContainer = ({
  name,
  description,
  type = A_LA_CARTE,
  courses,
  extras,
  options,
  ...props
}) => {
  // console.log('type:', type, 'courses:', courses, 'extras:', extras);
  return (
    <Box
      className="menu-container"
      sx={{
        pt: ['20.07%', '37.13%', '11.00%', '10.31%', '9.76%', '17%'],
        px: [0, 0, '5.11%', '12.64%', '7.56%', '7.87%'],
        ':last-of-type': {
          pb: ['20.07%', '37.13%', '11.00%', '10.31%', '0%', '0%']
        }
      }}
      {...props}>
      {type === MENU ? (
        <MenuBlock
          title={name}
          description={description}
          items={mergeCourseAndExtrasMenu(courses, extras)}
        />
      ) : null}
      {options.map((option) => (
        <MenuBlock
          key={option.name}
          title={option.name}
          description={description}
          items={option.items}
        />
      ))}
    </Box>
  );
};

// Helper function
const getExtrasMenuItems = (extrasMenu = []) =>
  extrasMenu.map((menu) => ({
    name: menu.name,
    price: `+ ${menu.price}`
  }));

const mergeCourseAndExtrasMenu = (courses, extras) => {
  return [...courses, ...getExtrasMenuItems(extras)];
};
