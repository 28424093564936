import React from 'react';

// External Components
import { Button, GridWrapper, GridItem } from '@thepuzzlers/pieces';

// Local Components
import { Vector } from 'components';
import { MenuWrapper } from '.';

// assets
import callIcon from 'assets/svg/callIcon.svg';

// Main Components
export const MobileMenuDisplay = ({
  dineInText,
  dineOutText,
  dineInMenu,
  dineOutMenu,
  contactLink
}) => {
  return (
    <GridWrapper
      clasName="menu-display-mobile"
      sx={{
        display: 'grid',
        zIndex: 90,
        bg: 'white',
        overflow: 'scroll',
        height: '100%'
      }}>
      <GridItem
        sx={{
          gridColumn: [
            '1/13',
            '2 / span 10',
            '3 / span 20',
            '1 / 25',
            null,
            null
          ],
          pb: ['28%', '28%', '28%', '15%', null, null]
        }}>
        <MenuWrapper title={dineInText} menu={dineInMenu} />
        <MenuWrapper title={dineOutText} menu={dineOutMenu} />
      </GridItem>
      <Button
        variant="clear"
        as="a"
        href={contactLink.href}
        sx={{
          position: 'fixed',
          bottom: ['3%', '3%', '3%', '5%', null, null],
          right: ['7%', '9%', '8%', '7.3%', null, null],
          width: ['17.07%', '13.75%', '11.87%', '8.57%', null, null],
          height: 'auto',
          display: 'block'
        }}>
        <Vector src={callIcon} />
      </Button>
    </GridWrapper>
  );
};
