import React from 'react';

// External Components
import { FlexWrapper, Heading, Paragraph, Section } from '@thepuzzlers/pieces';
import { colors } from 'gatsby-plugin-theme-ui/colors';

// Local Components
import { ImageContainer, DividerLine, Vector } from 'components';
const LottieContainer = React.lazy(() => import('components'));

// Assets
import water from 'assets/about/water.svg';
import benjamin from 'lotties/benjamin.json';
import hagen from 'lotties/hagen.json';

// Animations
import { vectorParallax, revealHeadlineAndLine } from 'sections/animations';

export const About = ({ data: { headline, image, paragraph, signatures } }) => {
  const sectionRef = React.useRef(null);

  React.useEffect(() => {
    const elements = [...sectionRef.current.children];

    const killRevealElements = revealHeadlineAndLine(
      sectionRef.current,
      elements[0],
      elements[1]
    );
    const killVectorAnimation = vectorParallax(elements[2], '300%');

    return () => {
      killRevealElements();
      killVectorAnimation();
    };
  }, []);

  return (
    <Section id="about" ref={sectionRef}>
      <Headline data={headline} />
      <Line />
      <Water />
      <Image {...image} />
      <TextBlock data={paragraph} />
      <Signatures />
      <SignatureCaption data={signatures.caption} />
    </Section>
  );
};

// Elements

const Headline = ({ data }) => (
  <Heading
    as="h3"
    type="h3-500-150"
    className="about__headline"
    sx={{
      color: 'primary',
      gridColumn: [
        '1/span 11',
        '1/span 9',
        '2/span 16',
        '2/span 12',
        '3/span 12',
        '4/span 11'
      ]
    }}>
    {data}
  </Heading>
);

const Line = () => (
  <DividerLine
    color={colors.primary}
    sx={{
      gridColumn: [
        '1/span 3',
        '2/span 2',
        '6/span 4',
        '7/span 3',
        '8/span 3',
        '9/span 2'
      ],
      mt: ['52.67%', '67.98%', '53.05%', '50.49%', '44.96%', '69.48%']
    }}
  />
);

const Image = ({ src, alt }) => (
  <ImageContainer
    className="about__image"
    image={src}
    alt={alt}
    coverBg="background"
    sx={{
      gridColumn: [
        '1/13',
        '2/span 10',
        '6/span 18',
        '7/span 15',
        '8/span 13',
        '9/span 11'
      ],
      gridRow: 3,
      mt: ['12.86%', '12.61%', '11.00%', '9.60%', '10.05%', '11.08%'],
      aspectRatio: '3/2'
    }}
  />
);

const TextBlock = ({ data }) => (
  <Paragraph
    type="p-500-175"
    className="about__text-block"
    dangerouslySetInnerHTML={{
      __html: data
    }}
    sx={{
      gridColumn: [
        '1/13',
        '2/span 10',
        '6/span 17',
        '7/span 15',
        '8/span 13',
        '9/span 11'
      ],
      mt: ['12.23%', '18.68%', '11.26%', '11.24%', '12.10%', '16.96%']
    }}
  />
);

const Signatures = () => (
  <FlexWrapper
    id="about__signatures-wrapper"
    sx={{
      alignItems: 'center',
      gridColumn: [
        '1/13',
        '2/span 8',
        '6/span 14',
        '7/span 12',
        '8/span 11',
        '9/span 9'
      ],
      justifyContent: 'space-between',
      mt: ['20.07%', '18.79%', '17.68%', '14.70%', '15.66%', '17.03%']
    }}>
    <React.Suspense fallback={<div />}>
      <LottieContainer
        loop={false}
        animationData={benjamin}
        sx={{ flexBasis: '51.5%' }}
      />
      <LottieContainer
        loop={false}
        animationData={hagen}
        sx={{ flexBasis: '38.6%' }}
      />
    </React.Suspense>
  </FlexWrapper>
);

const SignatureCaption = ({ data }) => (
  <Paragraph
    type="p-500-175"
    className="about__signature-caption"
    sx={{
      gridColumn: [
        '1/span 5',
        '2/span 4',
        '6/span 5',
        '7/span 5',
        '8/span 4',
        '9/span 3'
      ],
      mt: ['12.31%', '9.67%', '11.64%', '17.49%', '8.76%', '21.91%']
    }}>
    {data}
  </Paragraph>
);

// Decoration

const Water = () => (
  <Vector
    src={water}
    alt={null}
    className="about__water-decoration"
    sx={{
      alignSelf: 'start',
      gridColumn: [
        '10/span 3',
        '10/span 3',
        '20/25',
        '19/span 5',
        '19/span 4',
        '18/span 4'
      ],
      gridRow: 3,
      justifySelf: 'end',
      mixBlendMode: 'multiply',
      m: [
        '106.67% 0 0',
        '81.31% 0 0',
        '88.78% 0 0',
        '67.05% -3% 0 0',
        '67.05% 7% 0 0',
        '63.99% 14% 0 0'
      ],
      position: 'relative',
      width: ['100%', '89%', '100%', '90%', '91%', '81%'],
      zIndex: 3
    }}
  />
);
