import React from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// External Components
import { Heading, Box, Section } from '@thepuzzlers/pieces';

// Local Components
import { Vector, SpiralOne, SpiralTwo } from 'components';

// Assets
import circleDrop from 'assets/svg/waterdrop.svg';
import triangleDrop from 'assets/svg/waterDrop2.svg';

// Animations
import { revealHeader } from './animations';

// Main Components
export const Header = ({ data: { topCaptions, headline, bottomCaptions } }) => {
  const headerRef = React.useRef(null);

  let lastHeight = 0;
  let lastWidth = 0;
  const setDocHeight = () => {
    if (
      Math.abs(lastWidth - window.innerWidth) > 100 ||
      Math.abs(lastHeight - window.innerHeight) > 100
    ) {
      lastHeight = window.innerHeight;
      lastWidth = window.innerWidth;

      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight / 100}px`
      );
    }
  };

  React.useEffect(() => {
    // Fix body while playing header animation
    const body = document.getElementsByTagName('BODY')[0];
    body.style.overflow = 'hidden';
    ScrollTrigger.refresh();

    setDocHeight();
    window.addEventListener('resize', setDocHeight);
    window.addEventListener('orientationchange', setDocHeight);
  }, []);

  // Reload page at the top
  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'auto' });
  }, []);

  React.useEffect(() => {
    const elements = [...headerRef.current.children];

    document.fonts.ready.then(function () {
      // Run animation after all the fonts have finished
      // loading to avoid breaks on split text
      const killAnimation = revealHeader(elements);

      return killAnimation;
    });
  }, []);

  return (
    <Section
      id="landing-page__header"
      as="header"
      ref={headerRef}
      sx={{
        height: [
          'calc(var(--vh) * 100)',
          null,
          'calc(var(--vh) * 90)',
          null,
          null,
          'calc(var(--vh) * 80)'
        ],
        gridTemplateRows: [null, null, null, null, null, '1fr auto 1fr'],
        alignContent: 'flex-start',
        // Animation value
        overflowX: 'hidden'
      }}>
      <TopCaption topCaptions={topCaptions} />
      <Headline headline={headline} />
      <BottomCaption bottomCaptions={bottomCaptions} />
      <SectionDecoration />
      <SpiralOne />
      <SpiralTwo />
    </Section>
  );
};

const TopCaption = ({ topCaptions }) => {
  return (
    <Box
      sx={{
        gridColumn: [
          '1 / span 8',
          '1 / span 7',
          '1 / span 8',
          '1 / span 7',
          '1 / span 7',
          '2 / span 5'
        ],
        gridRow: 1,
        mt: ['42.19%', '59.62%', '86.20%', '18.95%', '20.30%', '0'],
        textAlign: 'left',
        alignSelf: 'end'
      }}>
      {topCaptions.map((caption) => (
        <Caption key={caption} text={caption} />
      ))}
    </Box>
  );
};

const Headline = ({ headline }) => {
  return (
    <Heading
      as="h1"
      type="h1-500-135"
      sx={{
        gridColumn: [
          '1/13',
          '3 / span 8',
          '4 / span 18',
          '5 / span 16',
          '5 / span 16',
          '6 / span 14'
        ],
        gridRow: 2,
        textAlign: 'center',
        mt: ['23.52%', '20.26%', '15.17%', '7.89%', '12.06%', '5.54%'],
        mb: [null, null, null, null, null, '5.54%'],
        px: [null, null, null, '2%', '0%', '0%'],
        // Animation values
        visibility: 'hidden',
        '& > .split-parent': {
          overflow: 'hidden'
        }
      }}>
      {headline}
    </Heading>
  );
};

const BottomCaption = ({ bottomCaptions }) => {
  return (
    <Box
      sx={{
        gridColumn: [
          '6 / span 7',
          '7 / span 6',
          '18/25',
          '18/25',
          '19/25',
          '19 / span 5'
        ],
        gridRow: 3,
        textAlign: 'right',
        alignSelf: 'start',
        mt: ['46.75%', '31.61%', '40.53%', '21.51%', '32.82%', 0]
      }}>
      {bottomCaptions.map((caption) => (
        <Caption key={caption} text={caption} />
      ))}
    </Box>
  );
};

const SectionDecoration = () => {
  return (
    <>
      <Vector
        id="header__decoraton-circle-drop"
        src={circleDrop}
        sx={{
          position: 'absolute',
          width: ['6.4%', '4.6%', '4.6%', '3%', '3%', '3%'],
          // Animation value
          visibility: 'hidden'
        }}
      />
      <Vector
        id="header__decoraton-triangle-drop"
        src={triangleDrop}
        sx={{
          position: 'absolute',
          width: ['5.1%', '3.5%', '3.2%', '2.4%', '2.2%', '2.2%'],
          // Animation value
          visibility: 'hidden'
        }}
      />
    </>
  );
};

// Reusable Components
const Caption = ({ text }) => {
  return (
    <Heading
      as="h2"
      type="h2-100-100"
      className="header__caption"
      sx={{
        color: 'primary',
        ':not(:first-of-type)': {
          mt: ['0.5em', '0.9em', '1.1em', '0.5em', '1.1em', '1em']
        },
        // Animation value
        visibility: 'hidden'
      }}>
      {text}
    </Heading>
  );
};
