import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

// Local Components
import { DineHeading, MenuContainer } from '.';
import { createIdFromName } from '../helper';

// Main Components
export const MenuWrapper = ({ title, menu }) => {
  return (
    <Box
      id={createIdFromName(title)}
      className="menu-show-case__menu-display"
      sx={{
        ':first-of-type': {
          pt: [null, null, null, null, '10%', '10%']
        },
        pt: ['25.08%', '40.87%', '12.60%', '12.79%', '11.05%', '25%']
      }}>
      <DineHeading primary={true} text={title} />
      {menu.map((item) => (
        <MenuContainer
          key={item.name + item.type}
          id={createIdFromName(item.name)}
          {...item}
        />
      ))}
    </Box>
  );
};
