import { graphql, useStaticQuery } from 'gatsby';

export const useMenuOverlayData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: menuOverlayDataJson {
        showText
        hideText
        contactLink {
          name
          href
        }
        dineInText
        dineOutText
      }
    }
  `);
  return data;
};
