import React from 'react';

// External Components
import { Section } from '@thepuzzlers/pieces';

// Local Components
import { ImageContainer } from 'components';
const LottieContainer = React.lazy(() => import('components'));

// Assets
import beefLottie from 'assets/lotties/Beef.json';
import fishLottie from 'assets/lotties/Fish.json';
import leafLottie from 'assets/lotties/Leaf.json';

export const Impression = ({ data: { png } }) => {
  return (
    <Section id="impression">
      <Beef />
      <ImageOne {...png[0]} />
      <ImageTwo {...png[1]} />
      <Fish />
      <ImageThree {...png[2]} />
      <Vegetable />
    </Section>
  );
};

// Elements

// Pngs

const ImageOne = ({ src, alt }) => (
  <ImageContainer
    image={src}
    alt={alt}
    coverBg="background"
    sx={{
      gridColumn: [
        '1/13',
        '3/span 9',
        '7/span 15',
        '6/span 12',
        '8/span 10',
        '8/span 10'
      ],
      gridRow: [2, 2, 2, 1, 1, 1],
      justifySelf: 'center',
      mt: ['5.02%', '4.16%', '3.66%', 0, 0, 0],
      width: ['100%', '100%', '100%', '100%', '100%', '89%'],
      aspectRatio: '150/100'
    }}
  />
);

const ImageTwo = ({ src, alt }) => (
  <ImageContainer
    image={src}
    alt={alt}
    coverBg="background"
    sx={{
      alignSelf: ['start', 'start', 'start', 'center', 'center', 'center'],
      gridColumn: [
        '1/span 8',
        '1/span 6',
        '4/span 10',
        '3/span 8',
        '4/span 7',
        '6/span 6'
      ],
      gridRow: 3,
      mt: ['22.28%', '34.77%', '29.23%', 0, 0, 0],
      aspectRatio: '1/1'
    }}
  />
);

const ImageThree = ({ src, alt }) => (
  <ImageContainer
    image={src}
    alt={alt}
    coverBg="background"
    sx={{
      gridColumn: [
        '5/13',
        '6/span 6',
        '12/span 10',
        '14/span 8',
        '15/span 7',
        '14/span 6'
      ],
      gridRow: [4, 4, 4, 3, 3, 3],
      mt: ['23.23%', '34.77%', '30.62%', 0, 0, 0],
      aspectRatio: '100/150'
    }}
  />
);

// Decorations

const Beef = () => (
  <React.Suspense fallback={<div />}>
    <LottieContainer
      animationData={beefLottie}
      sx={{
        gridColumn: [
          '8/span 4',
          '9/span 3',
          '18/span 4',
          '20/span 3',
          '20/span 3',
          '19/span 3'
        ],
        gridRow: 1,
        justifySelf: ['end', 'end', 'end', 'end', 'start', 'start'],
        alignSelf: [null, null, null, 'center', 'center', 'center'],
        m: [
          '-20% -19% -13% 0',
          '-26% -21% -13% 0',
          '-13% -21% -13% 0',
          0,
          0,
          0
        ],
        transform: [
          null,
          'scale(0.9) translateX(5%)',
          'scale(1.22) translateX(-4.8%)',
          'scale(1.5) translateX(1%)',
          'scale(1.3) translateX(-7%)',
          'scale(1.3) translateX(-7%)'
        ]
      }}
    />
  </React.Suspense>
);

const Fish = () => (
  <React.Suspense fallback={<div />}>
    <LottieContainer
      animationData={fishLottie}
      sx={{
        alignSelf: 'start',
        gridColumn: [
          '10/span 2',
          '8/span 2',
          '15/span 3',
          '4/span 2',
          '5/span 2',
          '7/span 2'
        ],
        gridRow: [3, 3, 3, 2, 2, 2],
        m: [
          '82.64% 0 0 0',
          '93.87% 0 0 0',
          '90.45% 0 0 0',
          '65.71% 0 -67% 0',
          '55.32% 0 -67% 0',
          '52.11% 0 -61% 0'
        ],
        transform: [
          'scale(1.2)',
          'scale(1.1) translateX(-1%)',
          'scale(1.1) translateX(-1%)',
          'scale(1.2) translateX(2%)',
          'scale(1.1) translateX(-1%)',
          'scale(1.1) translateX(-1%)'
        ]
      }}
    />
  </React.Suspense>
);

const Vegetable = () => (
  <React.Suspense fallback={<div />}>
    <LottieContainer
      animationData={leafLottie}
      sx={{
        alignSelf: 'center',
        gridColumn: [
          '2/span 3',
          '4/span 2',
          '8/span 3',
          '11/span 2',
          '12/span 2',
          '11/span 2'
        ],
        gridRow: 4,
        m: [
          '64.53% 0 0 0 ',
          '112.87% 0 0 0',
          '113.62% 0 0 0',
          '15% 0 0 0',
          '8% 0 0 0',
          '9% 0 0 0'
        ],
        transform: [
          'translateX(-8%)',
          'scale(1.15) translateX(-6%)',
          'scale(1.25) translateX(1.5%)',
          'scale(1.35) translateX(-11%)',
          'scale(1.25) translateX(-5%)',
          'scale(1.25) translateX(-5%)'
        ]
      }}
    />
  </React.Suspense>
);
