import React from 'react';

// External Components
import { navigate } from 'gatsby';
import {
  Overlay,
  Box,
  GridWrapper,
  Button,
  FlexWrapper,
  GridItem
} from '@thepuzzlers/pieces';
import { AnimatePresence } from 'framer-motion';

// Local Components
import { Vector, CloseButton } from 'components';
import { MenuWrapper, DineHeading, MobileMenuDisplay } from './selfComponents';

// Data
import { useMenuOverlayData } from './useMenuOverlayData';

// Assets
import whiteTriangle from 'assets/svg/whiteTriangle.svg';
import blueRightTriangle from 'assets/svg/blue-right-triangle.svg';
import blueWave from 'assets/svg/blueWave.svg';

// Animation
import { animateActiveCategory } from './animation';

// Hooks
import { useMediaQuery } from '../../hooks/useMediaQuery';

// Helper function
import { createIdFromName } from './helper';

// Main Components
export const MenuOverlay = ({ handleClose, foodData: { dineIn, dineOut } }) => {
  return (
    <Overlay
      handleClose={handleClose}
      shouldCloseOnBackdropClick
      shouldCloseOnEscapePress>
      <OverlayContent
        dineIn={dineIn}
        dineOut={dineOut}
        handleClose={handleClose}
      />
    </Overlay>
  );
};

// Needs to be in a sub component of MenuOverlay, for refs to work correctly
const OverlayContent = ({ dineIn, dineOut, handleClose }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const isLargeScreen = useMediaQuery('(min-width: 1000px)');

  const toggleMenuNav = () => setIsMenuOpen((state) => !state);

  // Data
  const { hideText, showText, contactLink, dineInText, dineOutText } =
    useMenuOverlayData();

  return (
    <>
      <Box
        id="menu-show-case"
        sx={{
          height: 'auto',
          bg: 'primary',
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: [null, null, null, null, 0, 0], // this enable scroll
          zIndex: 100,
          overflowY: 'scroll',
          transition: 'all 0.5s ease'
        }}>
        <GridWrapper
          sx={{
            position: 'relative'
            // position: [
            //   'relative',
            //   'relative',
            //   'relative',
            //   'relative',
            //   'relative',
            //   'relative'
            // ],
            // top: [null, null, null, null, '5.38%', '4.4%']
          }}>
          <Spacer />
          <MenuNavBar
            toggleMenuNav={toggleMenuNav}
            showText={showText}
            hideText={hideText}
            contactLink={contactLink}
            isMenuOpen={isMenuOpen}
            onToggleMenuButtonClick={toggleMenuNav}
            handleClose={handleClose}
            dineInText={dineInText}
            dineOutText={dineOutText}
            dineOut={dineOut}
            dineIn={dineIn}
          />

          {isLargeScreen && (
            <MenuDisplay
              dineInText={dineInText}
              dineOutText={dineOutText}
              dineInMenu={dineIn}
              dineOutMenu={dineOut}
            />
          )}
          <Decorations />
          <Spacer />
        </GridWrapper>
      </Box>
      {!isLargeScreen && (
        <>
          {/* Hidden duplicate of the navbar to push the content down */}
          {/* this way its easier to navigate to the correct position in the overlay */}
          <GridWrapper
            className="menuNavBar--spacer"
            sx={{
              visibility: 'hidden'
            }}>
            <FlexWrapper
              sx={{
                gridColumn: ['1/13', '1/13', '1/25', '1/25', '24/25', '24/25'],
                py: ['5.64%', '3.10%', '2.26%', '1.89%', 0, 0]
              }}>
              <CallButton name="Anrufen" sx={{ gridColumn: '1/span 12' }} />
            </FlexWrapper>
          </GridWrapper>
          <MobileMenuDisplay
            dineInText={dineInText}
            dineOutText={dineOutText}
            dineInMenu={dineIn}
            dineOutMenu={dineOut}
            contactLink={contactLink}
            dineIn={dineIn}
          />
        </>
      )}
    </>
  );
};

// Elements

const MenuNavBar = ({
  showText,
  hideText,
  contactLink,
  isMenuOpen,
  toggleMenuNav,
  onToggleMenuButtonClick,
  handleClose,
  dineInText,
  dineOutText,
  dineIn,
  dineOut
}) => {
  const padding = ['5.64%', '3.10%', '2.26%', '1.89%', 0, 0];
  const isLargeScreen = useMediaQuery('(min-width: 1000px)');

  return (
    <>
      <FlexWrapper
        className="menu-show-case__nav-bar"
        sx={{
          gridColumn: ['1/13', '1/13', '1/25', '1/25', '24/25', '24/25'],
          gridRow: [null, null, null, null, 2, 2],
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: [null, null, null, null, 'start', 'start'],
          py: padding,

          position: [
            'relative',
            'relative',
            'relative',
            'relative',
            'fixed',
            'fixed'
          ],
          top: [null, null, null, null, '7%', '5%'],
          right: [null, null, null, null, '7%', '7%']
        }}>
        <ToggleMenuButton
          showText={showText}
          hideText={hideText}
          isMenuOpen={isMenuOpen}
          onClick={onToggleMenuButtonClick}
        />
        <RightSideButtons contactLink={contactLink} handleClose={handleClose} />
      </FlexWrapper>
      <AnimatePresence>
        {(isMenuOpen || isLargeScreen) && (
          <GridWrapper
            variant="inside.autoColumns"
            initial={{ height: '0vh' }}
            animate={{ height: '100vh' }}
            exit={{ height: '0vh' }}
            sx={{
              position: [
                'relative',
                'relative',
                'relative',
                'relative',
                'fixed',
                'fixed'
              ],
              top: [null, null, null, null, '7%', '5%']
            }}
            transition={{ tween: 'true' }}>
            <MenuSelections
              dineInText={dineInText}
              dineOutText={dineOutText}
              dineInMenu={dineIn}
              dineOutMenu={dineOut}
              contactLink={contactLink}
              isMenuOpen={isMenuOpen}
              toggleMenuNav={toggleMenuNav}
            />
          </GridWrapper>
        )}
      </AnimatePresence>
    </>
  );
};

const ToggleMenuButton = ({ showText, hideText, isMenuOpen, onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="menuToggle"
      sx={{
        color: 'white',
        width: ['50%', '34.3%', '26%', '20.3%', null, null],
        display: ['flex', 'flex', 'flex', 'flex', 'none', 'none']
      }}>
      <Box
        as="span"
        sx={{
          whiteSpace: 'nowrap',
          textAlign: 'left'
        }}>
        {isMenuOpen ? hideText : showText}
      </Box>
      <Vector
        src={whiteTriangle}
        sx={{
          //animation value
          width: ['9%', '9%', '11%', '10%', null, null],
          transform: isMenuOpen ? 'rotate(0deg)' : 'rotate(180deg)',
          transformOrigin: 'center',
          transition: 'all 0.2s ease'
        }}
      />
    </Button>
  );
};

const RightSideButtons = ({ contactLink: { name, href }, handleClose }) => {
  return (
    <FlexWrapper
      className="menu-show-case__navbar__right-side-button"
      sx={{
        justifyContent: [
          'space-between',
          'space-between',
          'space-between',
          'space-between',
          'flex-end',
          'flex-start'
        ],
        width: ['44.2%', '48.6%', '33.5%', '22.2%', '100%', '100%'],
        alignItems: 'center'
      }}>
      <CallButton
        name={name}
        href={href}
        sx={{
          display: ['block', 'block', 'block', 'block', 'none', 'none']
        }}
      />
      <CloseButton
        onClick={() => {
          handleClose();
          window.location.hash = '';
        }}
        sx={{
          width: ['15%', '15%', '15%', '15%', '90%', '100%']
        }}
      />
    </FlexWrapper>
  );
};

const MenuSelections = ({
  dineInText,
  dineOutText,
  dineInMenu,
  dineOutMenu,
  contactLink,
  isMenuOpen,
  toggleMenuNav
}) => {
  const handleLinkClick = (linkName, index, dineTitle) => {
    // Get dine option clicked to navigate to
    const dineHref = dineTitle === 'IN HAUS' ? '#in-haus' : '#ausser-haus';

    // If the menu is open close it before navigating to sub category
    if (isMenuOpen) toggleMenuNav();

    // If first subcategory navigate to category
    // Else navigate to subcategory
    return navigate(index === 0 ? dineHref : `#${createIdFromName(linkName)}`);
  };

  return (
    <Box
      className="menu-show-case__menu-navigation"
      sx={{
        gridColumn: [
          '2 / span 8',
          '2 / span 8',
          '3 / span 11',
          '2 / span 22',
          '1 / span 6',
          '2 / span 6'
        ],
        gridRow: [null, null, null, null, 1, 1],
        display: [null, null, null, 'flex', 'block', 'block']
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}>
      <DineNavigation
        dineTitle={dineInText}
        menu={dineInMenu}
        handleLinkClick={handleLinkClick}
      />
      <DineNavigation
        dineTitle={dineOutText}
        menu={dineOutMenu}
        handleLinkClick={handleLinkClick}
      />
      <CallButton
        name={contactLink.name}
        href={contactLink.href}
        sx={{
          display: [
            'none',
            'none',
            'none',
            'none',
            'inline-block',
            'inline-block'
          ],
          mt: [null, null, null, null, '19.69%', '20.78%']
        }}
      />
    </Box>
  );
};

const Decorations = () => {
  return (
    <Vector
      className="menu-show-case__decorations__blue-wave"
      src={blueWave}
      sx={{
        display: ['none', 'none', 'none', 'none', 'block', 'block'],
        gridColumn: [null, null, null, null, '22/25', '22/25'],
        gridRow: [null, null, null, null, 2, 2],
        alignSelf: 'end',
        position: [
          'relative',
          'relative',
          'relative',
          'relative',
          'fixed',
          'fixed'
        ],
        top: [null, null, null, null, '80vh', '80vh'],
        right: '0',
        transform: [
          null,
          null,
          null,
          null,
          'translateX(-60%)',
          'translateX(-80%)'
        ],
        zIndex: -1
      }}
    />
  );
};

const MenuDisplay = ({ dineInText, dineOutText, dineInMenu, dineOutMenu }) => {
  return (
    <Box
      className="menu-display"
      sx={{
        display: ['none', 'none', 'none', 'none', 'block', 'block'],
        gridColumn: [null, null, null, null, '7 / span 16', '8 / span 15'],
        gridRow: [null, null, null, null, '2 / span 2', '2 / span 2'],
        p: [
          null,
          null,
          null,
          null,
          // the top is 0 in order to give each Menu
          // has it's own padding to add more space to
          // the top of each menu when link navigate to it
          '0% 6.33% 4.67% 6.33%',
          '0% 6.80% 15% 6.80%'
        ],
        borderRadius: '12px',
        minHeight: '80vh',
        bg: 'white'
      }}>
      <MenuWrapper title={dineInText} menu={dineInMenu} />
      <MenuWrapper title={dineOutText} menu={dineOutMenu} />
    </Box>
  );
};

// Reused Components
const DineNavigation = ({ dineTitle, menu, handleLinkClick }) => {
  return (
    <Box
      className="dine-in-menu"
      sx={{
        flexBasis: [null, null, null, '50%', 'auto', 'auto'],
        ':first-of-type': {
          mt: ['9.48%', '11.74%', '17.65%', '1.27%', 0, 0]
        },
        ':not(:first-of-type)': {
          mt: ['30.34%', '37.87%', '35.29%', '1.27%', '19.69%', '20.78%'],
          transform: [null, null, null, 'translateX(1.7%)', 'unset', 'unset']
        }
      }}>
      <DineHeading text={dineTitle} />
      <DineOptions
        menu={menu}
        dineTitle={dineTitle}
        handleLinkClick={handleLinkClick}
      />
    </Box>
  );
};

const DineOptions = ({ menu, dineTitle, handleLinkClick }) => {
  // Get location for active link
  const location = decodeURI(window.location.hash);
  const dineLocation = `#${createIdFromName(dineTitle)}`;

  return menu.map((item, index) => {
    // Check if first sub category is active
    const isFirstLinkActive = dineLocation === location ? true : false;
    // Check if any sub category is active to be
    // used for all sub categories minus the first
    const isLinkActive = `#${createIdFromName(item.name)}` === location;

    return (
      <DineLink
        key={item.name}
        name={item.name}
        index={index}
        dineTitle={dineTitle}
        handleClick={handleLinkClick}
        isActive={index === 0 ? isFirstLinkActive : isLinkActive}
      />
    );
  });
};

const DineLink = ({ name, index, dineTitle, handleClick, isActive }) => {
  return (
    <Button
      as="a"
      onClick={() => handleClick(name, index, dineTitle)}
      variant="buttons.menuToggleSmall"
      className="menu-overlay__dine-link"
      sx={{
        width: '100%',
        textAlign: 'left',
        color: !isActive ? 'white' : 'secondary',
        position: 'relative',
        whiteSpace: 'nowrap',
        ':hover': {
          color: 'secondary',
          '& > .dine-link__blue-arrow': {
            transform: 'scale(1) translateY(-50%)'
          }
        },
        ':first-of-type': {
          mt: ['9.96%', '8.81%', '10.08%', '8.25%', '9.85%', '9.42%']
        },
        ':not(:first-of-type)': {
          mt: ['12.33%', '9.39%', '10.08%', '7.62%', '13.13%', '9.74%']
        }
      }}>
      <Vector
        className="dine-link__blue-arrow"
        src={blueRightTriangle}
        sx={{
          position: 'absolute',
          top: '50%',
          transform: !isActive
            ? 'scale(0) translateY(-50%)'
            : 'scale(1) translateY(-50%)',
          left: ['-13%', '-7.4%', '-9.4%', '-9.3%', '-11.3%', '-11.3%'],
          transition: 'all 0.2s ease-out'
        }}
      />
      {name}
    </Button>
  );
};

const CallButton = ({ name, href, sx }) => (
  // this button placed in two diferrent places
  <Button as="a" href={href} variant="callButton" sx={sx}>
    {name}
  </Button>
);

const Spacer = () => {
  return (
    <GridItem
      className="spacer"
      sx={{
        display: [null, null, null, null, 'block', 'block'],
        mt: [null, null, null, null, '4.91%', '3.13%']
      }}
    />
  );
};
