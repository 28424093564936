import React from 'react';

// External Components
import { Heading, Section } from '@thepuzzlers/pieces';

// Local Components
import { StoryBubble } from 'components';

// Animations
import { revealTextLines } from 'sections/animations';

// Main Components
export const Story = ({ data: { headline } }) => {
  return (
    /**
     * For this section, The Decorations components placed on top of section,
     * because the Decoration item is only one and placed on the top of the section
     * and to avoid adding gridRow style to the elements to minimize the code
     */
    <Section id="story">
      <Decorations />
      <Headline headline={headline} />
    </Section>
  );
};

const Headline = ({ headline }) => {
  const headlineRef = React.useRef(null);

  React.useEffect(() => {
    document.fonts.ready.then(function () {
      // Run animation after all the fonts have finished
      // loading to avoid breaks on split text
      const killAnimation = revealTextLines(headlineRef.current);

      return killAnimation;
    });
  }, []);

  return (
    <Heading
      as="h3"
      type="h3-500-150"
      ref={headlineRef}
      sx={{
        gridColumn: [
          '1/13',
          '2 / span 10',
          '4 / span 18',
          '2 / span 22',
          '3 / span 20',
          '4 / span 18'
        ],
        textAlign: 'center',
        color: 'primary',
        mt: ['4.70%', '11.21%', '12.14%', '10.16%', '9.39%', '8.27%'],
        // Animation value
        '& > .split-parent': {
          overflow: 'hidden'
        }
      }}>
      {headline}
    </Heading>
  );
};

const Decorations = () => {
  return (
    <StoryBubble
      sx={{
        gridColumn: [
          '8 / span 4',
          '8 / span 2',
          '18 / span 4',
          '19 / span 3',
          '19 / span 2',
          '18 / span 2'
        ],
        width: ['92.29%', '100%', '83.77%', '93.41%', '100%', '100%'],
        justifySelf: 'start'
      }}
    />
  );
};
