import { graphql, useStaticQuery } from 'gatsby';

export const useFoodData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: foodDataJson {
        dineIn {
          name
          description
          type
          courses {
            name
            description
            price
          }
          extras {
            name
            price
          }
          options {
            name
            items {
              name
              description
              price
            }
          }
        }
        dineOut {
          name
          description
          type
          courses {
            name
            description
            price
          }
          # extras {
          #   name
          #   price
          # }
          options {
            name
            items {
              name
              description
              price
            }
          }
        }
      }
    }
  `);
  return data;
};
