import React from 'react';

// External Components
import {
  Section,
  Heading,
  Box,
  fullWidthMinusMargins,
  Button
} from '@thepuzzlers/pieces';

// Local Components
import { Vector } from 'components';

// Assets
import waterDrop from 'assets/contact/waterDrop.svg';

// Animations
import { vectorParallax, buttonHoverVariant } from 'sections/animations';

// Hooks

// Main Components
export const Contact = () => {
  return (
    <Section
      id="contact"
      sx={{
        // spacing
        pt: ['10%', '10%', '10%', '10%', '10%', '10%']
      }}>
      <HeadlineBlock />
    </Section>
  );
};

// Elements
const HeadlineBlock = () => {
  return (
    <Box
      className="contact__headline-block"
      sx={{
        gridColumn: [
          '1/13',
          '1/13',
          '2 / span 22',
          '2 / span 22',
          '2 / span 22',
          '4 / span 18'
        ],
        mx: [fullWidthMinusMargins[0], 0, 0, 0, 0, 0],
        bg: 'primary',
        p: [
          '24.00% 7.47% 24.00% 7.47%',
          '12.99% 8.53% 12.99% 8.53%',
          '12.67% 9.27% 12.67% 9.27%',
          '9.68% 13.80% 9.68% 13.80%',
          '11.58% 13.77% 11.58% 13.77%',
          '10.57% 11.30% 10.57% 11.30%'
        ],
        position: 'relative',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Heading
        as="h3"
        type="h3-300-150"
        sx={{
          '&>a': { color: 'secondary', textDecoration: 'underline' },
          color: 'primaryNegative',
          textAlign: 'center'
        }}>
        Erreichen Sie uns per{' '}
        <a href="mailto:bestellung@zweierlei-restaurant.de">Email</a>,<br />
        oder unter der Telefonnummer
        <br />
        <a href="tel:+49 211 9894587">+49 211 9894587</a>
        <br />
        <br />
        Oder{' '}
        <a
          href="https://www.thefork.com/restaurant/zweierlei-r597835"
          target="__blank"
          // onClick={handleOpenReservationModal}
        >
          reservieren Sie hier
        </a>{' '}
        direkt einen Tisch.
      </Heading>

      <Decoration />
    </Box>
  );
};

const Decoration = () => {
  const decorationRef = React.useRef(null);

  React.useEffect(() => {
    const killVectorAnimation = vectorParallax(decorationRef.current, '-300%');

    return () => {
      killVectorAnimation();
    };
  }, []);

  return (
    <Vector
      designatedRef={decorationRef}
      id="contact__water-drop"
      src={waterDrop}
      sx={{
        position: 'absolute',
        bottom: ['-2.7%', '10.8%', '12%', '14%', '16%', '13.3%'],
        right: ['12.6%', '-5.7%', '-3%', '-5%', '-3.5%', '-5.8%'],
        width: ['14.8%', '12%', '10%', '8%', '7%', '8.3%'],
        mixBlendMode: 'multiply'
      }}
    />
  );
};
