import React from 'react';

// External Components
import { Heading, Paragraph, Section } from '@thepuzzlers/pieces';
import { colors } from 'gatsby-plugin-theme-ui/colors';

// Local Components
import { ImageContainer, Vector, DividerLine } from 'components';

// Assets
import forkAndKnife from 'assets/offer/fork-knife.svg';

// Animations
import { revealHeadlineAndLine } from 'sections/animations';

export const Offer = ({ data: { headline, paragraph, images } }) => {
  const sectionRef = React.useRef(null);

  React.useEffect(() => {
    const elements = [...sectionRef.current.children];

    const killRevealElements = revealHeadlineAndLine(
      elements[0],
      elements[0],
      elements[1]
    );

    return killRevealElements;
  }, []);

  return (
    // Markup
    <SectionWrapper designatedRef={sectionRef}>
      <Headline data={headline} />
      <Line />
      <ForkAndKnife />
      <ImageOne {...images[0]} />
      <TextBlock data={paragraph} />
      <ImageTwo {...images[1]} />
    </SectionWrapper>
  );
};

// Elements

const SectionWrapper = ({ children, designatedRef }) => (
  <Section
    id="events"
    ref={designatedRef}
    sx={{
      bg: 'primary',
      gridTemplateRows: [
        null,
        null,
        null,
        'auto auto auto 1fr auto auto',
        'auto auto auto 1fr auto auto',
        'auto auto auto 1fr auto auto'
      ],
      // spacing
      pt: ['10%', '10%', '10%', '10%', '10%', '10%']
    }}>
    {children}
  </Section>
);

const Headline = ({ data }) => (
  <Heading
    as="h2"
    type="h2-700-115"
    className="offer__headline"
    sx={{
      color: 'light',
      gridColumn: [
        '1/span 9',
        '2/span 7',
        '3/span 12',
        '2/span 9',
        '3/span 8',
        '4/span 7'
      ],
      gridRow: [1, 1, 1, '1/span 2', '1/span 2', '1/span 2']
    }}>
    {data}
  </Heading>
);

const Line = () => (
  <DividerLine
    color={colors.background}
    sx={{
      gridColumn: [
        '1/span 3',
        '2/span 2',
        '3/span 4',
        '2/span 3',
        '3/span 3',
        '4/span 2'
      ],
      gridRow: [2, 2, 2, 3, 3, 3],
      mt: ['43.46%', '48.74%', '43.75%', '56.80%', '47.51%', '56.45%']
    }}
  />
);

const ImageOne = ({ src, alt }) => (
  <ImageContainer
    image={src}
    alt={alt}
    coverBg="primary"
    sx={{
      alignSelf: 'start',
      gridColumn: [
        '1/13',
        '3/span 9',
        '7/span 15',
        '13/span 11',
        '13/span 10',
        '13/span 9'
      ],
      gridRow: [3, 3, 3, '2/span 3', '2/span 3', '2/span 3'],
      mt: ['11.29%', '10.14%', '10.98%', 0, 0, 0],
      aspectRatio: '3/2'
    }}
  />
);

const ImageTwo = ({ src, alt }) => (
  <ImageContainer
    image={src}
    alt={alt}
    coverBg="primary"
    sx={{
      alignSelf: 'start',
      display: ['none', 'none', 'none', 'block', 'block', 'block'],
      gridColumn: [null, null, null, '2/span 8', '3/span 7', '4/span 6'],
      gridRow: [null, null, null, '4/span 3', '4/span 3', '4/span 3'],
      mt: [null, null, null, '20.96%', '19.95%', '17.53%'],
      aspectRatio: '2/3'
    }}
  />
);

const TextBlock = ({ data }) => (
  <Paragraph
    type="p-500-175"
    className="offer__text-block"
    dangerouslySetInnerHTML={{
      __html: data
    }}
    sx={{
      color: 'light',
      gridColumn: [
        '2/span 10',
        '3/span 9',
        '7/span 14',
        '13/span 12',
        '13/span 10',
        '13/span 8'
      ],
      gridRow: [4, 4, 4, '5/span 2', '5/span 2', '5/span 2'],
      mt: ['9.81%', '6.76%', '7.37%', '9.70%', '9.96%', '13.46%']
    }}
  />
);

// Decoration

const ForkAndKnife = () => (
  <Vector
    id="offer__fork-and-knife-decoration"
    src={forkAndKnife}
    alt={null}
    sx={{
      alignSelf: 'start',
      gridColumn: [
        '9/span 3',
        '10/13',
        '19/span 5',
        '22/25',
        '20/span 4',
        '20/span 4'
      ],
      gridRow: [3, 3, 3, '2/span 2', '1/span 3', '1/span 3'],
      justifySelf: ['start', 'end', 'end', 'start', 'end', 'start'],
      position: 'relative',
      transform: [
        'unset',
        'translateY(-10%)',
        'translateY(-10%)',
        'translateY(-50%)',
        'unset',
        'unset'
      ],
      width: ['100%', '82.2%', '88%', '100%', '88%', '80%'],
      zIndex: 3
    }}
  />
);
