import { graphql, useStaticQuery } from 'gatsby';

export const useLandingPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: landingPageDataJson {
        seo {
          title
          description
        }
        header {
          topCaptions
          headline
          bottomCaptions
        }
        impression {
          png {
            src {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            alt
          }
          svg {
            src {
              publicURL
            }
          }
        }
        about {
          headline
          image {
            src {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
            alt
          }
          paragraph
          signatures {
            svg {
              src {
                publicURL
              }
              alt
            }
            caption
          }
        }
        menu {
          textblock {
            headline
            description
          }
          menu {
            description
            icon {
              src {
                publicURL
              }
            }
            button {
              text
            }
          }
          pickup {
            description
            icon {
              src {
                publicURL
              }
            }
            button {
              text
            }
          }
        }
        offer {
          headline
          paragraph
          images {
            src {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            alt
          }
        }
        collaboration {
          headline
          paragraph
        }
        story {
          headline
        }
        map {
          headline
          paragraph
          mapImage {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
        }
      }
    }
  `);

  return data;
};
