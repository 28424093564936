// GSAP
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';

gsap.registerPlugin(MotionPathPlugin);

export const revealHeader = (elements) => {
  // Get elements to animate
  const [
    topCaptions,
    headline,
    bottomCaptions,
    circleDecoration,
    triangleDecoration
  ] = elements;
  const topCaptionsElements = [...topCaptions.children];
  const bottomCaptionsElements = [...bottomCaptions.children];

  // Split headline into lines to animate separately
  let childSplit = new SplitText(headline, {
    type: 'lines',
    linesClass: 'split-child'
  });

  let parentSplit = new SplitText(headline, {
    type: 'lines',
    linesClass: 'split-parent'
  });

  // Tween defaults
  gsap.defaults({ duration: 1, ease: 'Power3.easeOut' });

  // Timeline
  let tl = gsap.timeline();

  tl.to(headline, { autoAlpha: 1, duration: 0.1 }) // change visibility under the hood
    .to(triangleDecoration, {
      duration: 3,
      autoAlpha: 1,
      motionPath: {
        path: '#spiralPathOne',
        align: '#spiralPathOne',
        alignOrigin: [0.5, 0.5],
        autoRotate: true
      },
      ease: 'power3.inOut',
      onComplete: () => {
        floatVector(triangleDecoration, -80);
      }
    })
    .to(
      circleDecoration,
      {
        autoAlpha: 1,
        duration: 3,
        motionPath: {
          path: '#spiralPathTwo',
          align: '#spiralPathTwo',
          alignOrigin: [0.5, 0.5],
          autoRotate: true
        },
        ease: 'power3.inOut',
        onComplete: () => {
          floatVector(circleDecoration, 60);
        }
      },
      '<'
    )
    .set('body', { overflow: 'inherit' }) // allow body scroll
    .from(
      childSplit.lines,
      {
        yPercent: 100,
        stagger: 0.3,
        // Remove the extra markup when it's done
        onComplete: () => {
          parentSplit.revert();
          childSplit.revert();
        }
      },
      2.4
    )
    .fromTo(
      topCaptionsElements,
      { x: 100 },
      { autoAlpha: 1, x: 0, stagger: 0.2 },
      3
    )
    .fromTo(
      bottomCaptionsElements,
      { x: 100 },
      { autoAlpha: 1, x: 0, stagger: 0.2 },
      '<'
    );

  // Kill timeline function
  const killAnimation = () => {
    tl.pause(0).kill(true);
  };

  return killAnimation;
};

const floatVector = (vector, y) => {
  gsap.to(vector, {
    yPercent: y,
    repeat: -1,
    yoyo: true,
    duration: 4,
    ease: 'sine.inOut'
  });
};
