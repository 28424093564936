import React from 'react';

// External Componentes
import { Box, Heading, FlexWrapper, Paragraph } from '@thepuzzlers/pieces';

// Local Components
import { createIdFromName } from '../helper';

//Main Components
export const MenuBlock = ({ title, description, items }) => {
  return (
    <Box
      className={`menu-block-${createIdFromName(title)}`}
      sx={{
        ':not(:first-of-type)': {
          mt: ['20.07%', '37.13%', '11.00%', '10.31%', '9.76%', '17%']
        }
      }}>
      <MenuTitle text={title} />
      {description && <Description description={description} />}
      <MenuItems items={items} />
    </Box>
  );
};

const MenuTitle = ({ text, sx }) => {
  return (
    <Heading
      as="h3"
      type="h3-100-100"
      sx={{
        color: 'primary',
        textTransform: 'uppercase',
        ...sx
      }}>
      {text}
    </Heading>
  );
};

const MenuItems = ({ items }) => {
  return items.map((item, index) => (
    <MenuItem
      key={`menu-items-${index}`}
      name={item.name}
      description={item.description}
      price={item.price}
    />
  ));
};

// Reused Components
const MenuItem = ({ name, description, price }) => {
  return (
    <Box
      className="menu-item"
      sx={{
        ':first-of-type': {
          mt: ['5.64%', '6.54%', '4.36%', '3.89%', '5.29%', '4.50%']
        },
        ':not(:first-of-type)': {
          mt: ['7.52%', '5.60%', '4.55%', '3.89%', '6.51%', '7%']
        }
      }}>
      <HeadlineBlock name={name} price={price} />
      {description && <Description description={description} />}
    </Box>
  );
};

const HeadlineBlock = ({ name, price }) => {
  return (
    <FlexWrapper
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
      <Heading
        as="h4"
        type="h4-700-125"
        sx={{
          flexBasis: price ? ['65%', '70%', '75%', '80%', '70%', '75%'] : 'auto'
        }}>
        {name}
      </Heading>
      {price && (
        <Paragraph
          type="p-300-100"
          variant="bold"
          sx={{
            color: 'primary',
            flexBasis: ['35%', '30%', '25%', '20%', '30%', '25%'],
            textAlign: 'right'
          }}>
          {price}
        </Paragraph>
      )}
    </FlexWrapper>
  );
};

const Description = ({ description }) => {
  return (
    <Paragraph
      type="p-500-135"
      sx={{
        mt: ['0.5em', '0.47em', '0.44em', '0.53em', '0.44em', '0.25em'],
        width: '83%'
      }}>
      {description}
    </Paragraph>
  );
};
